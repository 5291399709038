.nrp-list {
  padding: 24px 32px;
  margin-bottom: 24px;
  margin-left: 32px;
  margin-right: 32px;
  border: 1px solid var(--color-grey-3);
  border-radius: 20px;
  box-shadow: 0 5px 18px -15px var(--color-dark-3);
  .table {
    td, th {
      border: 0 none
    }
  }
}