

.image-entity {
  background-color: rgba(0,0,0,0.1);
  display: inline-block;
  margin-right: 10px;

  &__upload-container {
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 4px;
    display: none;
    cursor: pointer;
  }

  .show.image-entity__upload-container {
    display: block;
  }

  &:hover .image-entity__upload-container {
    display: block;
  }

  &__content {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}