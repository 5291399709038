.nrp-input {
  width: 100%;
  padding: 8px 16px;
  border: 0 none;
  border-bottom: 1px solid var(--color-blue);
  display: block;
  margin-bottom: 24px;
  &:focus, &:active {
    outline: 0 none;
    border-bottom: 1px solid var(--color-blue);
  }
  &.nrp-input-error {
    border-bottom: 1px solid var(--color-red);
  }
}

.nrp-input-error-text {
  margin-top: 0px;
  padding: 2px 4px;
  font-size: 12px;
  color: var(--color-red)
}

