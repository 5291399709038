.nrp-searchbox {
  width: 300px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 5px 18px -12px var(--color-dark-3);
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  form {
    flex: 1;
    display: flex;
    padding: 10px 16px;
    align-items: center;
    justify-content: space-between;
  }
  input[type="text"] {
    flex: 1;
    border: 0 none;
    &:focus {
      border: 0 none;
      outline: 0 none;
    }
  }

}