// .react-responsive-modal-modal {
//     max-width: 100vw !important;
// }
.partner-popup-container {
    padding: 20px;
    overflow-x: hidden;

    .logo-and-name {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: flex-start;
    }
    
    label {
        display: block;
        font-weight: bold;
    }
    input, textarea {
        width: 100%;
        margin: 10px 0px;
        padding: 5px 15px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 8px;
        outline: none;
    }

    button {
        background-color: var(--color-blue);
        padding: 5px 15px;
        border: 1px solid #ccc;
        border-radius: 8px;
        cursor: pointer;
        margin: 0;
        color: white;

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
    }

    textarea {
        height: 150px;
    }
}

.currencies-container {
    display: flex;
    flex-direction: column;

    .currencies-row {
        display: flex;
        flex-direction: row;
        align-items: stretch;

        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;

        &:nth-child(2n) {
            background-color: #f5f5f5;
        }
    }

    .c-column {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 10px;
    }

    .c-column.logo {
        flex: unset;
        width: 200px;
    }
}