// define color variable
:root {
  // Clr/Primary
  --color-black: #000000;
  --color-dark: #1a1a1a;
  --color-dark-2: #262626;
  --color-dark-3: #333333;
  --color-dark-4: #9b9b9b;
  --color-white: #ffffff;
  --color-grey: #cccccc;
  --color-grey-2: #dcdcdc;
  --color-grey-3: #e6e6e6;
  --color-grey-4: #f4f4f4;
  --color-grey-5: #dddddd;
  // Clr/Palette
  --color-red: #b41f24;
  --color-red-dark: #a21b20;
  --color-red-light: #ef4231;
  --color-cream: #bfab99;
  --color-cream-dark: #ab9989;
  --color-cream-light: #e8d0ba;
  --color-green: #7ae5dd;
  --color-green-dark: #4ebbb4;
  --color-green-light: #a5ffff;
  --color-blue: #0EA3DA;
  --color-blue-dark: #0EA3DA;
  --color-blue-light: #9ad9e2;
}

// define margin class

$spaceamounts: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .m-#{str-slice($side, 0, 1)}-#{$space}p {
      margin-#{$side}: #{$space}% !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space}p {
      padding-#{$side}: #{$space}% !important;
    }
  }
}

.blf-margin-fix {
  padding-top: 1px;
  margin-top: -1px;
}

h1 {
  font-weight: bold;
  font-size: 96px;
  line-height: 112px;
  letter-spacing: -1.5px;

  @media screen and (max-width: 775px) {
    font-size: 42px;
    line-height: 48px;
  }
}

h2 {
  font-weight: bold;
  font-size: 60px;
  line-height: 71px;
  letter-spacing: -0.5px;

  @media screen and (max-width: 775px) {
    font-size: 42px;
    line-height: 48px;
  }
}

h3 {
  font-weight: bold;
  font-size: 48px;
  line-height: 57px;
  letter-spacing: 0px;

  @media screen and (max-width: 775px) {
    font-size: 28px;
    line-height: 33px;
  }
}

h4 {
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;

  @media screen and (max-width: 775px) {
    font-size: 19px;
    line-height: 24px;
  }
}

h5 {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0px;

  @media screen and (max-width: 775px) {
    font-size: 12px;
    line-height: 24px;
  }
}

h6 {
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.25px;
}

p {
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.5px;
}

p.small {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}
