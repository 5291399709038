.nrp-button {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  transition: .3s all ease-in-out;
  border: 1px;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;

  &.solid {
    background-color: var(--color-blue);
    color: white;
    border: 1px solid var(--color-blue);
    &:hover {
      background-color: var(--color-blue-light);
      border: 1px solid var(--color-blue-light);
    }



    &.primary {
      background-color: var(--color-blue);
      color: white;
      border: 1px solid var(--color-blue);
      &:hover {
        background-color: var(--color-blue-light);
        border: 1px solid var(--color-blue-light);
      }
    }

    &.danger {
      background-color: var(--color-red);
      color: white;
      border: 1px solid var(--color-red);
      &:hover {
        background-color: var(--color-red-light);
        border: 1px solid var(--color-red-light);
      }
    }

    &.warning {
      background-color: var(--color-cream);
      color: white;
      border: 1px solid var(--color-cream);
      &:hover {
        background-color: var(--color-cream-light);
        border: 1px solid var(--color-cream-light);
      }
    }

    &.success {
      background-color: var(--color-green);
      color: white;
      border: 1px solid var(--color-green);
      &:hover {
        background-color: var(--color-green-light);
        border: 1px solid var(--color-green-light);
      }
    }
    &.disabled {
      cursor: not-allowed;
      background: var(--color-grey);
      border: 1px solid var(--color-grey);

      &:hover {
        background-color: var(--color-grey);
        border: 1px solid var(--color-grey);
      }
    }
  }

  &.outline {
    background-color: transparent;
    &.primary {
      color: var(--color-blue);
      border: 1px solid var(--color-blue);
      &:hover {
        color: var(--color-blue-light);
        border: 1px solid var(--color-blue-light);
      }
    }

    &.success {
      color: var(--color-green);
      border: 1px solid var(--color-green);
      &:hover {
        color: var(--color-green-light);
        border: 1px solid var(--color-green-light);
      }
    }

    &.danger {
      color: var(--color-red);
      border: 1px solid var(--color-red);
      &:hover {
        color: var(--color-red-light);
        border: 1px solid var(--color-red-light);
      }
    }

    &.warning {
      color: var(--color-cream);
      border: 1px solid var(--color-cream);
      &:hover {
        color: var(--color-cream-light);
        border: 1px solid var(--color-cream-light);
      }
    }
  }
}