// .react-responsive-modal-modal {
//     max-width: 100vw !important;
// }
.confirm-import-container {
    padding: 20px;
    overflow-x: hidden;

    

    button {
        background-color: var(--color-blue);
        padding: 5px 15px;
        border: 1px solid #ccc;
        border-radius: 8px;
        cursor: pointer;
        margin: 0;
        color: white;

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }

        &.cancel {
            background-color: var(--color-red);
            margin-left: 10px;
        }
    }
}
