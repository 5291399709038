.nrp-color-circle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  opacity: 0.7;
  margin-right: 8px;
  cursor: pointer;
  &.active {
    opacity: 1
  }
}