.nrp-textarea {
  width: 100%;
  border: 0 none;
  box-shadow: 0 5px 18px -15px var(--color-dark-3);
  padding: 8px 16px;
  border-radius: 20px;
  margin-bottom: 32px;
  &:focus {
    outline: none;
  }
}