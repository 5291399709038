.nrp-select {
  margin-bottom: 24px;

  &.select-flat {
    .react-select__control {
      padding: 8px 16px;
      border: 0 none;
      border-radius: 0px;
      border-bottom: 1px solid var(--color-grey);
      outline: 0 none;
      &:hover {
        border-bottom: 1px solid var(--color-blue);
      }
    }
  }

  &.select-default {
    .react-select__control {
      padding: 8px 16px;
      border: 0 none;
      border-radius: 20px;
      outline: 0 none;
      box-shadow: 0 5px 18px -15px var(--color-dark-3);
    }
  }
  // background: black;

  .react-select__control--is-focused {
    outline: 0 none;
    border: 0 none;
    box-shadow: none;
    border-bottom: 1px solid var(--color-blue);
  }
  .react-select__value-container {
    padding: 0px;
  }
}