.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  .header-logo {
    align-self: center;
    flex: 1;
    text-align: center;
    img {
      height: 50px;
    }
  }
  .user-area {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      margin-right: 10px;
      border-radius: 40px;
    }
  }
}