.footer-container {
  position: relative;
  height: 50px;
  clear: both;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding-top: 20px;
  padding-right: 32px;
  padding-bottom: 32px;
  // position: absolute;
  // bottom: 0;
  // right: 0;

  background: #ffffff;
  // position: fixed;
  // bottom: 20px;
  width: 100%;
  // right: 40px;
  .footer-logo {
    // align-self: center;
    flex: 1;
    text-align: right;
    img {
      height: 20px;
    }
  }
  .user-area {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      margin-right: 10px;
      border-radius: 40px;
    }
  }
}

