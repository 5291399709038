.nrp-sidebar-item {
  display: flex;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  // height: 77px;
  text-decoration: none;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 14px;

  // transition: 0.3s all ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: var(--color-blue);
    color: #ffffff;
    text-decoration: none;
  }

  &.black-hover:hover {
    background-color: #000000;
    color: #ffffff;
    text-decoration: none;
  }

  &.dark-blue-hover:hover {
    background-color: #1300F4;
    color: #ffffff;
    text-decoration: none;
  }


  &.active {
    background-color: var(--color-blue);
  }

  &.black-hover.active {
    background-color: #000000;

  }

  &.dark-blue-hover.active {
    background-color: #1300F4;

  }

}