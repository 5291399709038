.nrp-checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  .nrp-checkbox {
    width: 24px;
    height: 23px;
    border: 1px solid var(--color-dark-3);
    border-radius: 5px;
    margin: 8px 8px 8px 0px; 
    align-items: center;
    justify-content: center;
    display: flex;
  }
  &.disabled {
    .nrp-checkbox {
      background-color: var(--color-grey-2)
    }
  }
}