.screen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-screen {
  background-image: linear-gradient(#FFFFFF, #1DC4ED);
}

.screen-overlay {
  background: rgba($color: #000000, $alpha: 0.333333);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  border: 1px solid var(--color-grey);
  border-radius: 20px;
  background: #ffffff;
  padding: 24px;
  width: 700px;
  display: flex;
  flex-direction: column;
  img {
    // height: 50px;
    width: 250px;
    // object-fit: contain;
    // display: block;
    // margin: 0 auto;
    margin: 32px 0px;
  }
  .forgot-password-link {
    text-align: right;
    a {
      text-decoration: none;
    }
  }
  .login-button {
    margin: 36px auto;
    width: 204px;
    display: block;
  }
}