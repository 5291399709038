@import "~bootstrap/scss/bootstrap";
@import "./variable.scss";

* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

html {
  scroll-behavior: smooth;
}
html,
body {
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
}

#root {
  height: 95vh;
}

#wrap {
  min-height: 100%;
}

#main {
  overflow: auto;
  // padding-bottom: 50px;
}